@import url('https://fonts.googleapis.com/css?family=PT+Mono');

.blurred-screen {
    position: absolute;
    top: 0%; left: 0%;
    width: 100%; height: 100%;
    background: #222d;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* EASY */

.win-easy {
    background-image: linear-gradient(30deg, hsl(116, 50%, 50%), hsl(116, 50%, 65%), hsl(116, 50%, 50%));
    border: .3rem solid hsl(116, 50%, 40%);
    box-shadow: 0px 14px 16px 8px hsla(116, 40%, 35%, 0.75);
    -webkit-box-shadow: 0px 14px 16px 8px hsla(116, 35%, 40%, 0.75);
    -moz-box-shadow: 0px 14px 16px 8px hsla(116, 40%, 35%, 0.75);
    filter: saturate(80%);
}

.screen-title-easy, .win-icon-easy {
    color:hsl(116, 35%, 35%);
}

.screen-title-easy::before {
    background-image: linear-gradient(75deg, hsl(116, 50%, 40%), hsl(116, 50%, 55%), hsl(116, 50%, 40%));
}

.screen-title-easy::after {
    background-image: linear-gradient(165deg, hsl(116, 50%, 40%), hsl(116, 50%, 55%), hsl(116, 50%, 40%));
}

.screen-description-easy {
    color:hsl(116, 40%, 40%);
}

/* MEDIUM */

.win-medium {
    background-image: linear-gradient(30deg, hsl(55, 50%, 50%), hsl(55, 50%, 65%), hsl(55, 50%, 50%));
    border: .3rem solid hsl(55, 50%, 40%);
    box-shadow: 0px 14px 16px 8px hsla(55, 40%, 35%, 0.75);
    -webkit-box-shadow: 0px 14px 16px 8px hsla(55, 35%, 40%, 0.75);
    -moz-box-shadow: 0px 14px 16px 8px hsla(55, 40%, 35%, 0.75);
    filter: saturate(80%);
}

.screen-title-medium, .win-icon-medium {
    color:hsl(55, 35%, 35%);
}

.screen-title-medium::before {
    background-image: linear-gradient(75deg, hsl(55, 50%, 40%), hsl(55, 50%, 55%), hsl(55, 50%, 40%));
}

.screen-title-medium::after {
    background-image: linear-gradient(165deg, hsl(55, 50%, 40%), hsl(55, 50%, 55%), hsl(55, 50%, 40%));
}

.screen-description-medium {
    color:hsl(55, 40%, 40%);
}

/* HARD */

.win-hard {
    background-image: linear-gradient(30deg, hsl(12, 50%, 50%), hsl(12, 50%, 65%), hsl(12, 50%, 50%));
    border: .3rem solid hsl(12, 50%, 40%);
    box-shadow: 0px 14px 16px 8px hsla(12, 40%, 35%, 0.75);
    -webkit-box-shadow: 0px 14px 16px 8px hsla(12, 35%, 40%, 0.75);
    -moz-box-shadow: 0px 14px 16px 8px hsla(12, 40%, 35%, 0.75);
    filter: saturate(80%);
}

.screen-title-hard, .win-icon-hard {
    color:hsl(12, 35%, 35%);
}

.screen-title-hard::before {
    background-image: linear-gradient(75deg, hsl(12, 50%, 40%), hsl(12, 50%, 55%), hsl(12, 50%, 40%));
}

.screen-title-hard::after {
    background-image: linear-gradient(165deg, hsl(12, 50%, 40%), hsl(12, 50%, 55%), hsl(12, 50%, 40%));
}

.screen-description-hard {
    color:hsl(12, 40%, 40%);
}

/* INSANE / MASTER */

.win-master {
    background-image: linear-gradient(30deg, hsl(182, 50%, 50%), hsl(182, 50%, 65%), hsl(182, 50%, 50%));
    border: .3rem solid hsl(182, 50%, 40%);
    box-shadow: 0px 14px 16px 8px hsla(182, 40%, 35%, 0.75);
    -webkit-box-shadow: 0px 14px 16px 8px hsla(182, 35%, 40%, 0.75);
    -moz-box-shadow: 0px 14px 16px 8px hsla(182, 40%, 35%, 0.75);
    filter: saturate(80%);
}

.screen-title-master, .win-icon-master {
    color:hsl(182, 35%, 35%);
}

.screen-title-master::before {
    background-image: linear-gradient(75deg, hsl(182, 50%, 40%), hsl(182, 50%, 55%), hsl(182, 50%, 40%));
}

.screen-title-master::after {
    background-image: linear-gradient(165deg, hsl(182, 50%, 40%), hsl(182, 50%, 55%), hsl(182, 50%, 40%));
}

.screen-description-master {
    color:hsl(182, 40%, 40%);
}

.time-text, .time-value {
    display: inline;
}

.time-value {
    font-family: 'PT';
    font-weight: 750;
    font-size: 1.3rem;
}

.choose-button.win-icon:hover {
    cursor: pointer;
    background: hsla(0, 0%, 45%, 0);  /*This declaration overrides default grey highlight on hover*/
    box-shadow: 0 0 .75rem .75rem hsla(0, 0%, 45%, 0); /*This declaration overrides default grey highlight on hover*/
    border-radius: 50%;
    filter: brightness(130%) saturate(150%);
    scale: 1.2;
    transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media screen and (orientation: landscape) and (max-width: 1000px) {
    /* Win screen for mobiles looks gr8 ! -> no media query needed */
    .win-screen {
        max-width: 50%;
    }
}

@media screen and (orientation: landscape) and (min-width: 1001px) {
    .win-screen {
        max-width: 35%;
    }
}

@media screen and (orientation: portrait) and (min-height: 540px) and (min-width: 540px) {
    .win-screen {
        max-width: 70%;
    }
}