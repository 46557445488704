.tile {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.tile:hover {
    cursor: pointer;
}

.tile-easy {
    border: .12rem solid hsla(116, 73%, 40%, .75); 
}

.tile-medium {
    border: .12rem solid hsla(55, 73%, 40%, .75); 
}

.tile-hard {
    border: .12rem solid hsla(12, 73%, 40%, .75); 
}

.tile-master {
    border: .12rem solid hsla(182, 73%, 40%, .75); 
}