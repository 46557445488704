@import url('https://fonts.googleapis.com/css?family=PT+Mono');

.timer-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.timer-icon {
    padding: .2rem;
    margin-right: .4rem;
}

.timer-count {
    font-family: 'PT';
    padding: .1rem;
    font-size: 1.5rem;
    font-weight: 900;
}