@import url('https://fonts.googleapis.com/css?family=Audiowide&effect=neon');

:root {
    --bg-easy: 'linear-gradient(315deg, hsl(144, 57%, 33%), hsla(144, 57%, 33%, 0))';
    --bg-medium: 'linear-gradient(45deg, hsl(55, 57%, 33%), hsla(55, 57%, 33%, 0))';
    --bg-hard: 'linear-gradient(225deg, hsl(12, 57%, 33%), hsla(12, 57%, 33%, 0))';
    --bg-insane: 'linear-gradient(135deg, hsl(248, 57%, 33%), hsla(348, 57%, 33%, 0))';
}

.layout {
    position: absolute;
    overflow: auto;
    top: 0%; left: 0%;
    width: 100%;
    height: 100%;
    background-color: #222;
    box-shadow: inset 0rem 0rem 7rem 1rem #111;
    color: #ddd;
}

.app-text {
    font-size: 1.1rem;
    margin: 1.3rem 0 .5rem 0;
}

.content-title-text {
    font-size: 2rem;
    letter-spacing: .09rem;
    text-shadow: 0 0 0.1em #fff, 0 0 0.2em #fff, 0 0 0.3em #fff, 0 0 0.4em
    hsl(180, 100%, 73%), 0 0 0.6em hsl(280, 100%, 50%), 0 0 0.8em hsl(222, 100%, 50%), 0 0 1.5em hsl(281, 100%, 50%), 0 0 1.2em hsl(181, 100%, 50%);
}

.content-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border: .075rem solid #ddd;
    border-left-color: #0000;
    border-right-color: #0000;
}

.box-difficulty {
    display: grid;
    grid-template-columns: repeat(2, 7.5rem);
    grid-template-rows: repeat(2, 5rem);
    padding: .75rem;
}

.difficulty {
    display: flex;
    align-items: center;
    justify-content: center;
    border: .2rem solid hsl(144, 55%, 65%);
    color:#333;
    font-weight: 600;
    padding: .4rem;
    margin: .1rem;
}

.content {
    font-family: 'Audiowide';
}

.difficulty-easy {
    background-image: var(--bg-easy);
    border-color: hsl(142, 75%, 25%);
    border-style: solid none none solid;
    box-shadow: inset .2rem .2rem .1rem .1rem hsl(142, 55%, 45%);
}

.difficulty-medium {
    background-image: var(--bg-medium);
    border-color: hsl(66, 75%, 35%);
    border-style: solid solid none none;
    box-shadow: inset -.2rem .2rem .1rem .1rem hsl(66, 55%, 55%);
}

.difficulty-hard {
    background-image: var(--bg-hard);
    border-color: hsl(22, 75%, 25%);
    border-style: none none solid solid;
    box-shadow: inset .2rem -.2rem .1rem .1rem hsl(22, 55%, 45%);
}

.difficulty-master {
    background-image: var(--bg-insane);
    border-color: hsl(274, 75%, 25%);
    border-style: none solid solid none;
    box-shadow: inset -.2rem -.2rem .1rem .1rem hsl(274, 55%, 45%);
}

.box-items {
    width: 40%;
    /* padding: .4rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.items-text {
    display: block;
    padding: .25rem .3rem;
    margin: .15rem 0 .65rem 0;
    word-wrap: break-word;
}

.items-vis {
    width: 3.3rem;
    height: 1.1rem;
    border: .15rem solid #ddd;
    position: relative;
    margin: .15rem;
    border-radius: 20%;
    background: transparent !important;
}

.items-vis label {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    transition: all .8s ease;
    cursor: pointer;
    position: absolute;
    top: -.2rem;
    left: -.2rem;
    border: .15rem solid #ddd;
}

.items-vis input[type=checkbox]:checked+label {
    left: 2rem;
    background-image: radial-gradient( hsl(133, 50%, 45%), hsl(112, 45%, 60%));
    border-radius: 20%;
    border: .1rem solid hsl(112, 40%, 60%);    
}

.item-option {
    background: #458;
    color: #379;
}

input[type=checkbox] {
    visibility: hidden;
}

input[type="checkbox"]:checked+input {
    background:hsl(112, 40%, 60%);
}

.items-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    border: .1rem solid;
    border-radius: 30%;
    margin: .3rem;
    width: 30vw;
    box-shadow: inset 2rem 1rem 1rem 1rem #222;
    background-image: radial-gradient(#444, #111 75%);
}

.items-icons[data-theme="day"] {
    filter: invert(100%);
}

.icon {
    font-size: 1.5rem;
    pointer-events: none;
   /*  box-shadow: 0 1rem 2rem 3rem #444; */
    border-radius: 30%;
}

.start {  
    /* Ivisible until player pick sudoku difficulty */
    padding: 1rem;
}

.start-play {
    margin-top: 1rem;
    padding: .6rem;
    width: 40vw;
    font-size: 1.2rem;
    font-weight: 550;
    border-radius: 555px;
    font-family: 'Audiowide', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-shadow: inset 0 0 2.6rem .1rem #7777, 0 0 1rem .1rem #777;
}

.start-play:hover {
    border-style: dotted;
}

.difficulty:hover, .items-icons:hover, .start-play:hover {
    cursor: pointer;
} 

/* very small devices - portrait mobiles (height no more than 600 px) */
@media screen and (orientation: portrait) and (max-height: 600px) {
    .content-title-text {
        font-size: 1.4rem;
    }

    .app-text {
        margin: .4rem 0 .2rem 0;
    }

    .app-text, .items-text, .difficulty {
        font-size: .8rem;
    }

    .box-difficulty {
        grid-template-columns: repeat(2, 4.5rem);
        grid-template-rows: repeat(2, 3.5rem);
    }

    .box-items {
        padding: .25rem;
    }

    .items-icons {
        padding: .6rem;
    }

    .start-play {
        height: 2rem;
        width: 5.2rem;
    }
}

/* Landscape orientation - any size mobiles from small to large viewports */ 
@media screen and (orientation: landscape) and (max-width: 1000px) { 
    /* Transform portrait (default) flow into horizontal grid */

    .app-section-box[data-type="difficulty"] {
        grid-area: difficulty-section;
    }

    .app-section-box[data-type="options"] {
        grid-area: options-section;
    }

    .app-section-box[data-type="theme"] {
        grid-area: theme-section;
    }

    .app-section-main {
        display: grid;
        grid-template-rows: 1;
        grid-template-columns: repeat(3, 30% 40% 30%);
        grid-template-areas: "theme-section   difficulty-section   options-section";
    }

    /* and... some styling ! */

    .content-title-text {
        font-size: 2rem;
        margin: .6rem;
    }

    .app-text {
        font-size: 1.1rem;
    }

    .difficulty, .items-text  {
        font-size: .8rem;
    }

    .items-text {
        padding: .45rem 0;
        display: inline;
    }

    .difficulty {
        padding: 1.5rem;
    }

    .box-difficulty {
        grid-template-columns: repeat(2, 16vw);
        grid-template-rows: repeat(2, 11vw);
    }

    .box-items {
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .content-box {
        border: none;
        padding: .4rem;
    }

    .content-box[data-type="content-options"] {
        display: grid;
        grid-template-columns: 1fr; /* Add extra 1fr there once option box grows - it'll make option box two-column */
        grid-template-rows: 12vw 12vw;
        align-items: center;
        justify-items: center;
    }

    .items-icons {
        width: 6.2vw;
        height: 6.2vw;
    }

    .items-vis {
        width: 7.5vw;
        height: 2.5vw;
    }

    .items-vis label {
        height: 2.5vw;
        width:  2.5vw;
    }

    .items-vis input[type=checkbox]:checked+label {
        left: 5vw;
        border-radius: 20%; 
    }

    .icon  {
        font-size: 2rem;
    }

    .start {
        padding: .2rem;
    }

    .start-play {
        width: 20vw;
        padding: .3rem;
        margin: .2rem;
    }
}

/* Square-resembling viewports (rather than rectangle-like) - tablets more typically - coming soon... */


/* Huge sized screens with landscape (non-mobiles) - ideal for desktops*/
@media screen and (orientation: landscape) and (min-width: 1001px) { 

    /* Transform portrait (default) flow into horizontal grid */

    .app-section-box[data-type="difficulty"] {
        grid-area: difficulty-section;
    }

    .app-section-box[data-type="options"] {
        grid-area: options-section;
    }

    .app-section-box[data-type="theme"] {
        grid-area: theme-section;
    }

    .app-section-main {
        display: grid;
        grid-template-rows: 1;
        grid-template-columns: repeat(3, 30% 40% 30%);
        grid-template-areas: "theme-section   difficulty-section   options-section";
        padding: .8rem;
    }

    /* and... some styling ! */

    .content-title-text {
        font-size: 2rem;
        padding-top: .5rem;
    }

    .app-text {
        font-size: 1.4rem;
    }
    
    .items-text, .difficulty {
        font-size: 1.1rem;
    }

    .box-difficulty {
        grid-template-columns: repeat(2, 15vw);
        grid-template-rows: repeat(2, 9vw);
    }

    .box-items {
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .content-box {
        border: none;
        padding: 2rem;
    }

    .content-box[data-type="content-options"] {
        display: grid;
        grid-template-columns: 1;
        grid-template-rows: auto;
        align-items: center;
        justify-items: center;
    }

    .items-icons {
        width: 7vw;
        height: 7vw;
    }

    .items-vis {
        width: 7.5vw;
        height: 2.5vw;
    }

    .items-vis label {
        height: 2.5vw;
        width:  2.5vw;
    }

    .items-vis input[type=checkbox]:checked+label {
        left: 5vw;
        border-radius: 20%; 
    }

    .icon  {
        font-size: 3rem;
    }

    .start {
        padding: 0;
    }

    .start-play {
        width: 20vw;
        padding: .75rem;
        margin: .1rem 0 .6rem 0;
    }
}

@media screen and (orientation: portrait) and (min-height: 540px) and (min-width: 540px) {
    /* Portrait-oriented tablets */

    .box-difficulty {
        grid-template-columns: repeat(2, 22.5vw);
        grid-template-rows: repeat(2, 15vw);
    }

    .items-icons {
        width: 8.5vw;
        height: 8.5vw;
    }

    .app-text, .items-text  {
        font-size: 1.3rem;
        font-weight: 550;
    }

    .items-vis {
        scale: 120%;
    }

    .difficulty {
        font-size:  1rem;
    }
    
}