.tool-box {
    margin: 1rem .5rem -0.8rem .5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.tool {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .3rem;
   /*  height: 4rem; */ min-height: 20vw;
   /*  width: 4rem; */ min-width: 20vw;
    border: .3rem double #fff;
    border-radius:  30%;
}

.tool:hover {
    cursor: pointer;
}

.tool-easy {
    border-color: hsl(116, 73%, 40%);
}

.tool-medium {
    border-color: hsl(55, 73%, 40%);
}

.tool-hard {
    border-color: hsl(12, 73%, 40%);
}

.tool-master {
    border-color: hsl(182, 73%, 40%);
}

.tool-icon {
    font-size: 1.5rem;
    pointer-events: none;
}

.desc {
    margin: .3rem .2rem;
}

.pencilmark_on {
    border-width: .3rem;
    border-style: dotted;
    animation: borderSpin 4s infinite linear;
}

/* .animation-box-easy {
    border: .3rem solid hsl(116, 73%, 40%);
}

.animation-box-medium {
    border: .3rem solid hsl(55, 73%, 40%);
}

.animation-box-hard {
    border: .3rem solid hsl(12, 73%, 40%);
}

.animation-box-master {
    border: .3rem solid hsl(182, 73%, 40%);
} */

.animation-box-easy::before, .animation-box-medium::before,
.animation-box-hard::before, .animation-box-master::before {
    position: relative;
    z-index: 40;
    top: 0%; left: 0%;
    width: 2rem; height: .5rem;
    background: #fff;
}

.tool-blocked {
    filter: grayscale(100%);
    pointer-events: none;
}

@keyframes borderSpin {
    0% {
        /* border: .3rem dotted hsl(12, 60%, 45%); */
        border-radius: 30%;
    }

    25% {
        /* border: .3rem dotted hsl(82, 60%, 45%); */
        border-radius: 40%;
    }

    50% {
        /* border: .3rem dotted  hsl(152, 60%, 45%); */
        border-radius: 50%;
    }

    75% {
       /*  border: .3rem dotted hsl(222, 60%, 45%); */
        border-radius: 40%;
    }

    100% {
       /*  border: .3rem dotted  hsl(292, 60%, 45%); */
        border-radius: 30%;
    }
}


@media screen and (orientation: landscape) and (max-width: 1000px) {
    .tool-box-all {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tool-box {
        margin: 0 auto;
        display: grid;
        gap: .15rem;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-template-areas: 
            "option-info"       
            "option-pencilmark"
            "option-btn-back"   
            "option-btn-forth";
        align-items: center;
        justify-content: center;
        justify-items: center;
    }
    
    .tool {
        width: 14.4vw;
        height: 7.2vw;
        min-height: 0; min-width: 0; /* set temporarily, get rid of during dev*/
    }

    .tool[data-name="info"] {
        grid-area: option-info;
    }

    .tool[data-name="back"] {
        grid-area: option-btn-back;
    }

    .tool[data-name="forth"] {
        grid-area: option-btn-forth;
    }

    .tool[data-name="pencil"] {
        grid-area: option-pencilmark;
    }

}




@media screen and (orientation: landscape) and (min-width: 1001px) {

    .tool-box-all {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tool-box {
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: 
            "option-info       option-pencilmark"
            "option-btn-back   option-btn-forth";
        align-items: center;
        justify-content: center;
        justify-items: center;
    }
    
    .tool-icon {
        font-size: 2.1rem;
    }

    .tool {
        width: 11vw;
        height: 11vw;
        min-height: 0; min-width: 0; /* set temporarily, get rid of during dev*/
    }

    .tool[data-name="info"] {
        grid-area: option-info;
    }

    .tool[data-name="back"] {
        grid-area: option-btn-back;
    }

    .tool[data-name="forth"] {
        grid-area: option-btn-forth;
    }

    .tool[data-name="pencil"] {
        grid-area: option-pencilmark;
    }
}

@media screen and (orientation: portrait) and (min-height: 540px) and (min-width: 540px) {
    /* Portrait-oriented tablets */
    .tool {
        width: 20vw;
        height: 12vw;
        min-height: 0; min-width: 0; /* set temporarily, get rid of during dev*/
    }
}