@import url('https://fonts.googleapis.com/css?family=Audiowide|Sofia');


/* Default styles goes for portrait-oriented mobile devices */

body {
    overflow-x: hidden !important; 
}

.all {
    width: 100%;
    height: 100%;
    font-family: 'Audiowide', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    overflow-x: hidden;
    /* box-shadow: inset 0rem 0rem 7rem 1rem #111; */
    background-color: #0000;
}

.sudoku-main {
    min-height: 100vh;
}

.sudoku-day {
    /* box-shadow: inset 0rem 0rem 7rem 1rem #777; */
    background: #ddd;
}

.sudoku-day, .all-day {
    color: #222;
}

.sudoku-night {
    /*box-shadow: inset 0rem 0rem 7rem 1rem #000; */    
    background: #111;
}

.sudoku-night, .all-night {
    color: #ddd;
}

.all-night::after, .all-day::after {
    content: '';
    display: block;
    position: absolute; top: 0%; left: 0%;
    z-index: -30;
    width: 100vw;
    height: 100vh;
}

.all-night::after {
    background: #000;
}

.all-day::after {
    background: #ddd;
}

.sudoku-title {
    padding: .6rem;
    font-size: 2rem;
}

.title-easy--day {
    text-shadow: -.1rem .2rem hsl(116, 65%, 45%);
}

.title-easy--night {
    text-shadow: -.1rem .2rem hsl(116, 45%, 40%);
}

.title-medium--day {
    text-shadow: -.1rem .2rem hsl(55, 65%, 45%);
}

.title-medium--night {
    text-shadow: -.1rem .2rem hsl(55, 45%, 40%);
}

.title-hard--day {
    text-shadow: -.1rem .2rem hsl(12, 65%, 45%);
}

.title-hard--night {
    text-shadow: -.1rem .2rem hsl(12, 45%, 40%);
}

.title-master--day {
    text-shadow: -.1rem .2rem hsl(182, 65%, 45%);
}

.title-master--night {
    text-shadow: -.1rem .2rem hsl(182, 45%, 40%);
}

.sudoku-map {
    padding:  2rem .2rem;
    display:  flex;
    align-items: center;
    justify-content: center;
}

.sudoku-board {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 33vw); /* grid-template-columns: repeat(3, 7rem) grid-template-columns: repeat(3, 33vw); */
    grid-template-rows: repeat(3, 33vw); /* grid-template-rows: repeat(3, 7rem) grid-template-rows: repeat(3, 33vw); */
    /* border: .2rem solid #000; */
}

.initial {
    font-weight: 800;
    font-family: 'Audiowide', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.palette {  /* Indicates main box for palette */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: .1rem;
}

.numbers-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
/*     grid-template-rows: repeat(3, 1fr); */
    width: 90%;
    border-radius: 10%;
}

.numbers-night-easy {
    border:  hsl(116, 73%, 30%);
}

.numbers-day-easy {
    border:  hsl(116, 60%, 40%);
}

.numbers-night-medium {
    border:  hsl(55, 73%, 30%);
}

.numbers-day-medium {
    border:  hsl(55, 60%, 40%);
}

.numbers-night-hard {
    border:  hsl(12, 73%, 30%);
}

.numbers-day-hard {
    border:  hsl(12, 60%, 40%);
}

.numbers-night-master {
    border:  hsl(182, 73%, 30%);
} 

.numbers-day-master {
    border: hsl(182, 60%, 40%);
}

.numbers-box .option {
    border: .15rem double;
    border-color: inherit;
}

.option {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border: .2rem dotted hsla(124, 5%, 5%, .6);
    font-weight: 700;
    border-radius: 10%;
    padding: .5rem;
}

.option-easy--day {
    text-shadow: .11rem .11rem hsl(116, 65%, 45%);
}

.option-easy--night {
    text-shadow: .11rem .11rem hsl(116, 45%, 40%);
}

.option-medium--day {
    text-shadow: .11rem .11rem hsl(55, 65%, 45%);
}

.option-medium--night {
    text-shadow: .11rem .11rem hsl(55, 45%, 40%);
}

.option-hard--day {
    text-shadow: .11rem .11rem hsl(12, 65%, 45%);
}

.option-hard--night {
    text-shadow: .11rem .11rem hsl(12, 45%, 40%);
}

.option-master--day {
    text-shadow: .11rem .11rem hsl(182, 65%, 45%);
}

.option-master--night {
    text-shadow: .11rem .11rem hsl(182, 45%, 40%);
}

.option:hover {
    cursor: pointer;
}

.option-blank {
    opacity: .3;
    pointer-events: none !important;
}

/*conflict tile */

/* DAY: background-image: radial-gradient(hsl(116, 75%, 75%), #0000 ) !important; */
/* NIGHT:     background-image: radial-gradient(hsl(116, 45%, 35%), #0000 70%) !important; */

.conflict-tile__easy--day {
    background-image: radial-gradient(hsl(116, 75%, 75%), #0000 ) !important;
}

.conflict-tile__easy--night {
    background-image: radial-gradient(hsl(116, 45%, 35%), #0000 70%) !important;
}

.conflict-tile__medium--day {
    background-image: radial-gradient(hsl(55, 75%, 75%), #0000 ) !important;
}

.conflict-tile__medium--night {
    background-image: radial-gradient(hsl(55, 45%, 35%), #0000 70%) !important;
}

.conflict-tile__hard--day {
    background-image: radial-gradient(hsl(12, 75%, 75%), #0000 ) !important;
}

.conflict-tile__hard--night {
    background-image: radial-gradient(hsl(12, 45%, 35%), #0000 70%) !important;
}

.conflict-tile__master--day {
    background-image: radial-gradient(hsl(182, 75%, 75%), #0000 ) !important;
}

.conflict-tile__master--night {
    background-image: radial-gradient(hsl(182, 45%, 35%), #0000 70%) !important;
}

/* When we append a number to a given tile with pencilmark option turn on*/

.pencilmark_tile {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3, 33%);
    grid-template-columns: repeat(3, 33%);
}

.xd {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    height: 33%;
    position: relative; 
    width: 33%;
    font-size: .6rem;
    font-weight: 650;
}

/* Chosen tile */

.active {
    animation: focus 4s infinite linear alternate;
}

.new-sudoku-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-sudoku {
    width: 40vw;
    margin: 1.6rem 0 1.1rem 0;
    padding: .6rem;
    color: #000;
    border-radius: 777px;
}

.new-sudoku:hover {
    cursor: pointer;
    filter: brightness(115%);
}

.new-sudoku-night-easy {
    color: hsla(116, 73%, 40%, .85);
    border: .2rem solid hsl(116, 73%, 30%);
}

.new-sudoku-day-easy {
    color: hsl(116, 73%, 30%);
    border: .2rem solid hsla(116, 73%, 40%, .85);
}

.new-sudoku-night-medium {
    color: hsla(55, 73%, 50%, .85);
    border: .2rem solid hsl(55, 73%, 30%);
}

.new-sudoku-day-medium {
    color:  hsl(55, 73%, 40%);
    border: .2rem solid hsla(55, 73%, 40%, .85);
}

.new-sudoku-night-hard {
    color: hsla(12, 73%, 40%, .85);
    border: .2rem solid   hsl(12, 73%, 30%)
}

.new-sudoku-day-hard {
    color: hsl(12, 73%, 30%); 
    border: .2rem solid hsla(12, 73%, 40%, .85);
}


.new-sudoku-night-master {
    color: hsla(182, 73%, 40%, .85);
    border: .2rem solid  hsl(182, 73%, 30%);
}

.new-sudoku-day-master {
    color:  hsl(182, 73%, 30%);
    border: .2rem solid  hsla(182, 73%, 40%, .85);
}

@keyframes colored {
    0% {
        background-image: radial-gradient(#222, #555, #888, #bbb, #eee);
    }

    25% {
        background-image: radial-gradient(#555, #888, #bbb, #eee, #222);
    }

    50% {
        background-image: radial-gradient(#888, #bbb, #eee, #222, #555);
    }

    75% {
        background-image: radial-gradient(#bbb, #eee, #222, #555, #888,);
    }

    100% {
        background-image: radial-gradient(#eee, #222, #555, #888, #bbb);
    }
}

@keyframes focus {
    0% {
        border: .3rem solid #222c;
        border-radius: 10%;
        background-color: #2227;
    }

    25% {
        border: .3rem solid #555c;
        border-radius: 10%;
        background-color: #5557;
    }

    50% {
        border: .3rem solid #888c;
        border-radius: 10%;
        background-color: #8887;
    }

    75% {
        border: .3rem solid #bbbc;
        border-radius: 10%;
        background-color: #bbb7;
    }

    100% {
        border: .3rem solid #eeec;
        border-radius: 10%;
        background-color: #eee7;
    }
}

@media screen and (orientation: landscape) and (max-width: 1000px) {
    /* Landscape oriented screen for mobiles */

    .game-elems {
        display: grid;
        grid-template-columns: 27% 46% 27%;
        grid-template-rows: auto;
    }

    .xd {
        font-size: .5rem;
    }

    .tile {
        font-size: .9rem;
    }

    .sudoku-map {
        padding: 1.2rem .2rem;
    }

    .sudoku-board {
        grid-template-rows:    repeat(3, 15.2vw);                /* repeat(3, 6rem);  */
        grid-template-columns: repeat(3, 15.2vw);               /* repeat(3, 6rem); */
    }

    .numbers-box {
        display: grid;
        grid-template-columns:none;
        grid-template-rows: repeat(3, 1fr);
        width: 80%;
    }

    .option {
        height: 4.8vw;
    }

    .option-0 { /*aka. the rubber*/
        grid-area: rubber;
    }

    .new-sudoku {
        width: 30vw;
        margin: .4rem 0 1.1rem 0;
        padding: .55rem;
    }
    
}



@media screen and (orientation: landscape) and (min-width: 1001px) {

    .sudoku-main {
        position: absolute; top: 0; left: 0;
        min-height: 100vh; min-width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    body{
        overflow-y: hidden;
    }

    .sudoku-day {
        box-shadow: inset 0rem 0rem 7rem 1rem #777;
    }

    .sudoku-night {
        box-shadow: inset 0rem 0rem 7rem 1rem #000;
    }

    .sudoku-title {
        font-size: 2.5rem;
    }

    .numbers-box {
        border-radius: 5%;
    }

    .game-elems {
        display: grid;
        grid-template-columns: 27% 46% 27%;
        grid-template-rows: auto;
    }

    .xd {
        font-size: .5rem;
    }

    .tile {
        font-size: 1.3rem;
    }

    .sudoku-map {
        padding: 1.2rem .2rem;
    }

    .sudoku-board {
        grid-template-rows: repeat(3, 11vw); /* 8 rem */
        grid-template-columns: repeat(3, 11vw); /* 8 rem */
        max-width: 45vw;
    }

    .numbers-box {
        display: grid;
        grid-template-columns: repeat(3, 8vw); /* 7.3vw */
        grid-template-rows: repeat(3, 8vw); /* 7.3vw */
        grid-template-areas: 
            "digit   digit   digit"
            "digit   digit   digit"
            "digit   digit   digit"
            "rubber  rubber  rubber"
        ;
    }

    .option {
        font-size: 2rem;
        padding: .8rem;
    }

    .option-0 { /*aka. the rubber*/
        grid-area: rubber;
        padding: 1.3rem;
    }

    .new-sudoku {
        width: 25vw;
        margin: .4rem 0 1.1rem 0;
        padding: .55rem;
        font-size: 1.15rem;
    }
}

@media screen and (orientation: portrait) and (min-height: 620px) and (min-width: 620px) {
    /* Portrait-oriented tablets */
    html{
        font-size: 1.3rem;
    }

    .sudoku-day {
        box-shadow: inset 0rem 0rem 7rem 1rem #777;
    }

    .sudoku-night {
        box-shadow: inset 0rem 0rem 7rem 1rem #000;
    }
    
    .sudoku-board {
        font-size: 1.6rem;
        grid-template-columns: repeat(3, 30vw); /* grid-template-columns: repeat(3, 7rem) grid-template-columns: repeat(3, 33vw); */
        grid-template-rows: repeat(3, 30vw); /* grid-template-rows: repeat(3, 7rem) grid-template-rows: repeat(3, 33vw); */
    }

    .option {
        font-size: 1.4rem;
        padding: .5rem;
    }
    
    .new-sudoku {
        width: 40vw;
        margin: 1.6rem 0 1.1rem 0;
        padding: .7rem;
        font-size: 1.4rem;
    }
}