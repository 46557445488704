.loading {
    position: absolute; top: 0%; left: 0%;
    height: 100%; width: 100%;
    pointer-events: none;
    box-shadow: inset 0rem 0rem 7rem 1rem #111;
}

.loading[data-theme="day"] {
    background:#111;
}

.loading[data-theme="night"] {
    background:#111;
}

.loading-content {
    position: relative; top: 25vh;
    max-height: 50vh;
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: auto;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
}

.spinner-div {
    margin-bottom: 2.5rem;
}

.loading-spinner {
    width: 12rem; height: 12rem;
    font-size: 10rem;
}

.loading-spinner[data-theme="day"] {
    color: #ddd;
}

.loading-spinner[data-theme="night"] {
    color: #ddd;
}

.loading-text {
    color: #ddd;
    font-size: 3.8rem;
    flex-wrap: wrap;
    margin-top: 2.5rem;
}

.loading-text[data-theme="day"] {
    color: #ddd;
}

.loading-text[data-theme="night"] {
    color: #ddd;
}


@media screen and (orientation: landscape) and (max-width: 1100px) {

    .loading-spinner {
        width: 10rem;
        height: 10rem;
        font-size: 8rem;
    }

    .spinner-div {
        margin-bottom: 1.5rem;
    }

    .loading-text {
        font-size: 3rem;
        margin-top: 1.5rem;
    }
    
}

@media screen and (orientation: landscape) and (min-width: 1101px) {
    /* All fine ! */
}