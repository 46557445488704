.square {
    box-sizing: border-box;
    display: grid;
    width: 100%;
    height: 100%;
}

.square-day {
    border: .12rem solid #222;
}

.square-night {
    border: .12rem solid #ddd;
}

.square:nth-of-type(1), .square:nth-of-type(2), .square:nth-of-type(3) {
    border-top-width: .3rem;
}

.square:nth-of-type(1), .square:nth-of-type(4), .square:nth-of-type(7) {
    border-left-width: .3rem;
}

.square:nth-of-type(7), .square:nth-of-type(8), .square:nth-of-type(9) {
    border-bottom-width: .3rem;
}

.square:nth-of-type(3), .square:nth-of-type(6), .square:nth-of-type(9) {
    border-right-width: .3rem;
}

