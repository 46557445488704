.blurred-theme, .blurred-screen {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%; height: 100%;
    background: #222d;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-box, .win-screen, .info-box {
    background-image: linear-gradient(30deg, #777, #aaa, #777);
    border: .3rem solid #444;
    max-width: 80%;
    padding: .4rem;
    border-radius: 5%;
    z-index: 40;
    box-shadow: 0px 14px 16px 8px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 14px 16px 8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 14px 16px 8px rgba(0,0,0,0.75);
}


.reset-title::before, .reset-title::after,
.screen-title::before, .screen-title::after
{
    content: '';
    display: block;
    background: #666;
    height: .4rem;
    border-radius: 30% 20% 30% 20%;
}

.reset-title::before, .screen-title::before {
    transform: translateX(1rem);
    background-image: linear-gradient(75deg, #888, #777, #888);
}

.reset-title::after, .screen-title::after {
    transform: rotateX(-1rem);
    background-image: linear-gradient(165deg, #888, #777, #888);
}

.reset-title, .screen-title {
    letter-spacing: .15rem;
    padding: .3rem;
    margin: 1rem;
    color: #000;
    font-size: 1.3rem;
    font-weight: 600;
    z-index: 40;
}

.message-box, .screen-description {
    color: #000;
    font-size: 1.1rem;
    margin: 1rem;
    z-index: 40;
    padding: .2rem;
}

.choose-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 40;
    padding: 1rem;
}

.choose-button {
    font-size: 1.8rem;
    z-index: 40;
}

.choose-button:hover {
    cursor: pointer;
    background: hsla(0, 0%, 45%, .65);
    box-shadow: 0 0 .75rem .75rem hsla(0, 0%, 45%, .65);
    border-radius: 50%;
    filter: brightness(130%) saturate(150%);
    scale: 1.2;
    transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.choose-decline {
    color: hsl(3, 60%, 45%);
}

.choose-confirm {
    color: hsl(120, 60%, 45%);
}

@media screen and (orientation: landscape) and (max-width: 1000px) {

    .reset-box, .win-screen, .info-box {
        max-width: 50%;
    }

    .reset-title, .screen-title {
        font-size: 1.2rem;
    }

    .message-box, .screen-description {
        font-size: 1rem;
    }

    .choose-box {
        padding: .6rem;
    }
}

@media screen and (orientation: landscape) and (min-width: 1001px) {
    .reset-box, .win-screen, .info-box {
        max-width: 40%;
    }

    .reset-title, .screen-title {
        font-size: 1.5rem;
    }

    .message-box, .screen-description {
        font-size: 1.2rem;
    }

    .choose-box {
        padding: 1.3rem;
    }
}

@media screen and (orientation: portrait) and (min-height: 540px) and (min-width: 540px) {
    .reset-box, .win-screen, .info-box {
        max-width: 65%;
    }
}